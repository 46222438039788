import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as Cell from 'exceljs/dist/exceljs.min.js';
import { FileManipulationService } from '../file-manipulation/file-manipulation.service';

@Injectable()
export class ExcelService {
  constructor(
    private fileManipulationService: FileManipulationService,
    private datePipe: DatePipe
  ) {}

  public generateExcelInstallmentAudit(
    fileName: string,
    title: string,
    header: Array<string>,
    data: Array<any>
  ): void {
    const workbook = new Cell.Workbook();
    const worksheet = workbook.addWorksheet(fileName);

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Calibri', family: 4, size: 16, bold: true };

    worksheet.addRow([]);
    worksheet.addRow([this.datePipe.transform(new Date(), 'medium')]);

    worksheet.addRow([]);
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    let color: string;
    let row: Cell.Row;
    let qty: Cell;
    let qty2: Cell;

    data.forEach((d) => {
      row = worksheet.addRow(d);

      qty = row.getCell(5);
      qty.value = qty.value ? qty.value.toString() : '';

      qty = row.getCell(6);
      qty.value = qty.value ? qty.value.toString() : '';

      if (qty.value) {
        const currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
        qty.value = qty.value.replace(',', '.');
        qty.value = currencyPipe.transform(qty.value, 'BRL', true);
      }

      qty = row.getCell(8);
      qty.value = qty.value ? qty.value.toString() : '';
      if (qty.value === 'true') {
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '6fed64',
          },
        };
      } else {
        qty.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'f44336',
          },
        };
      }
      qty.value = '';

      qty = row.getCell(9);
      qty2 = row.getCell(10);
      color = qty2.value.toString();
      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: color,
        },
      };
      qty2.value = '';
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 40;
    worksheet.addRow([]);

    this.generateExcelFile(workbook, fileName);
  }

  public generateExcelFile(workbook: Cell.Workbook, fileName: string) {
    fileName = this.fileManipulationService.formatFilenameReport(
      [fileName],
      '.xlsx'
    );

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      this.fileManipulationService.saveAs(
        blob,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        fileName
      );
    });
  }

  public generateExcel(
    fileName: string,
    header: Array<string>,
    data: Array<any>
  ): void {
    const _fileName: string = `${fileName}_${this.datePipe.transform(
      new Date(),
      'dd_MM_yyyy'
    )}`;
    const workbook = new Cell.Workbook();
    const worksheet = workbook.addWorksheet(_fileName);
    worksheet.addRow(header);
    data.forEach((d) => {
      worksheet.addRow(d);
    });

    this.generateExcelFile(workbook, _fileName);
  }
}
