import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile } from '../../shared/models/profile.model';
import { State } from '../../shared/state/state';
import { URL_API } from 'src/environments/app.api';
import { PersonLogged } from 'src/app/shared/models/person-logged.model';
import { lastValueFrom } from 'rxjs';

export const PERSON_ME_API = `${URL_API}/persons/me`;
export const PROFILE_PIC_URL = `${URL_API}/persons/avatar`;
export const DEFAULT_AVATAR = 'assets/img/empty_user.png';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends State<Profile> {
  constructor(private http: HttpClient) {
    super();
  }

  async initProfile(): Promise<void> {
    const personLogged = await lastValueFrom(this.http.get<PersonLogged>(`${PERSON_ME_API}`));
    if (personLogged) {
      await this.setCurrentState({
        access: personLogged?.access,
        name: personLogged?.name || null,
        admin: personLogged?.admin || null,
        email: personLogged?.email || null,
        surname: personLogged?.surname || null,
        team: personLogged.team || null,
      });
    }
  }
}
