export const environment = {
  production: false,
  shouldPromptTokenRequest: false,
  appCodeDevMode: 'adminconsignado',
  DEV: {
    TOKEN_URL:
      'https://api-adminconsignado.dev.totvs.app/token/:token?appCode=adminconsignado',
    REFRESH_TOKEN_URL:
      'https://api-adminconsignado.dev.totvs.app/refresh-token',
    NOCODE_API: 'https://api-fluig.dev.totvs.app',
    IDENTITY_URL: 'https://app.fluigidentity.net',
  },
  STAGING: {
    TOKEN_URL: '',
    REFRESH_TOKEN_URL: '',
    NOCODE_API: '',
    IDENTITY_URL: '',
  },
  PROD: {
    TOKEN_URL: '',
    REFRESH_TOKEN_URL: '',
    NOCODE_API: '',
    IDENTITY_URL: '',
  },
  authorityEndPoint: 'https://totvs.rac.dev.totvs.app/totvs.rac',
  apiEndPoint: 'https://api-adminconsignado.dev.totvs.app/api/portal/v1',
  clientBaseAddress: 'https://adminconsignado.dev.totvs.app',
};
