import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OAuthLoginComponent } from './oauth-login.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OAuthLoginComponent],
  exports: [OAuthLoginComponent],
})
export class OauthLoginModule {}
