<app-header class="fluig-style-guide"></app-header>

<div class="po-wrapper">
  <ng-container>
    <po-menu *ngIf="menuService.state$ | async as menu" [p-menus]="menu">
      <ng-template p-menu-header-template>
        <img
          src="{{ this.getFileURL('assets/img/poweredby-TOTVS-Techfin.svg') }}"
        />
      </ng-template>
    </po-menu>
  </ng-container>

  <div class="top-div"></div>

  <div>
    <router-outlet></router-outlet>
  </div>
</div>

<!-- <tpl-spin-loader [absolute]="true"></tpl-spin-loader> -->
