import { HttpClient } from '@angular/common/http';
import { PoTableColumnSort, PoTableColumnSortType } from '@po-ui/ng-components';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs/operators';
import { TotvsPage, TotvsQueryOptions } from '../models/totvs';
import { State } from '../state/state';
import { RepositoryData } from './repositorydata.model';

/**
 * Classe representando o estado atual de um repositório exposto
 * via uma API no padrão totvs
 */
export class Repository<T extends { id: string }> extends State<
  RepositoryData<T>
> {
  public static readonly DefaultPageSize = 10;
  private options: TotvsQueryOptions = {};
  public isLoading = false;
  public all$ = this.state$.pipe(map<RepositoryData<T>, T[]>((s) => s.all));

  /**
   * Construtor padrão
   * @param url url base da api
   * @param client client http
   * @param refreshOnChange deve resetar o componenete toda vez que houver uma mudança?
   */
  constructor(
    private readonly url: string,
    private readonly client: HttpClient,
    private readonly refreshOnChange = true,
    options: TotvsQueryOptions = {}
  ) {
    super();
    this.setOptions(options);
    void this.setCurrentState(new RepositoryData<T>());
  }

  /**
   * Limpa o estado do repositório e recupera a primeira página do server
   * @param options Opções passadas para a api
   */
  async initToFirstPage(): Promise<void> {
    if (!this.isLoading) {
      this.isLoading = true;
      try {
        const state = new RepositoryData<T>();
        await this.appendPage(state, 1);
        await this.setCurrentState(state);
      } finally {
        this.isLoading = false;
      }
    }
  }

  /**
   * Seta as novas options mas não modifica nenhum estado para tal chame o clear ou o initFirstPage para que
   * o estado fique sincronizado em relação ao server
   * @param options novas options
   */
  setOptions(options: TotvsQueryOptions): void {
    this.options = Object.assign(
      {},
      { pageSize: Repository.DefaultPageSize },
      options
    );
  }

  /**
   * Atualiza uma entidade presente no repositório junto com o server
   * @param entity entidade a ser atualizada
   */
  async update<R>(id: string, updateRequest: R): Promise<T> {
    const state: RepositoryData<T> = await this.getNewState();
    const newEntity = await this.client
      .put<T>(`${this.url}/${id}`, updateRequest)
      .toPromise();
    state.dataBase[newEntity.id] = newEntity;
    if (this.refreshOnChange) {
      await this.appendPage(state, 1);
    }
    await this.setCurrentState(state);
    return newEntity;
  }

  /**
   * Cria uma entidade no server e no repositório
   * @param entity entidade a ser criada no server
   */
  async create(entity: T): Promise<T> {
    const state: RepositoryData<T> = await this.getNewState();
    const newEntity = await this.client
      .post<T>(this.url, {
        ...(entity as object),
        id: undefined,
      })
      .toPromise();
    state.dataBase[newEntity.id] = newEntity;
    if (this.refreshOnChange) {
      await this.appendPage(state, 1);
    }
    await this.setCurrentState(state);
    return newEntity;
  }

  private async getNewState(): Promise<RepositoryData<T>> {
    let state: RepositoryData<T>;
    if (this.refreshOnChange) {
      state = new RepositoryData<T>();
    } else {
      state = cloneDeep(await this.getCurrentState());
    }
    return state;
  }

  /**
   * Deleta uma entidade no server e no repositório
   * @param id id da entidade a ser deletada
   */
  async delete(id: string): Promise<void> {
    const state: RepositoryData<T> = await this.getNewState();
    await this.client.delete(`${this.url}/${id}`).toPromise();
    delete state.dataBase[id];
    if (this.refreshOnChange) {
      await this.appendPage(state, 1);
    }
    await this.setCurrentState(state);
  }

  /**
   * Recupera a próxima página do server e armazena no estado interno
   */
  async goToNextPage(): Promise<void> {
    if (!this.isLoading) {
      this.isLoading = true;
      try {
        const currentState = await this.getCurrentState();
        await this.appendPage(currentState, currentState.lasPageFetched + 1);
      } finally {
        this.isLoading = false;
      }
    }
  }

  /*
   * Atulizar configuração da ordenação
   */
  async setColumnOrder(columnSort: PoTableColumnSort): Promise<void> {
    const currentState = await this.getCurrentState();
    if (currentState.hasNext) {
      const options: TotvsQueryOptions = this.options;
      options.order = this.GetOrder(columnSort);
      this.setOptions(options);
      await this.appendPage(currentState, 1, true);
    }
  }

  /*
   * Atulizar filtros
   */
  async setPropertyFilters(filters: {
    [key: string]: string | string[];
  }): Promise<void> {
    const options: TotvsQueryOptions = this.options;
    options.propertyFilters = filters;
    options.pageSize = 10;
    this.setOptions(options);
    const currentState = await this.getCurrentState();
    await this.appendPage(currentState, 1, true);
  }

  async setPropertyFiltersNoPaginate(filters: {
    [key: string]: string | string[];
  }): Promise<void> {
    const options: TotvsQueryOptions = this.options;
    options.propertyFilters = filters;
    options.pageSize = 1000;
    this.setOptions(options);
    const currentState = await this.getCurrentState();
    await this.appendPage(currentState, 1, true);
  }

  /**
   * Reseta o componenente para o estado inicial
   */
  async clear(): Promise<void> {
    await this.setCurrentState(new RepositoryData<T>());
  }

  /**
   * Recupera uma entidade do server se não estiver presente no repositorio
   * @param id id da entidade
   * @param useCache se existir deve recuperar da cache interna
   */
  async getOne(id: string, useCache = true): Promise<T> {
    const state = cloneDeep(await this.getCurrentState());
    let entity: T;
    if (useCache && state.dataBase[id]) {
      entity = state.dataBase[id];
    } else {
      entity = await this.getOneFromServer(id, state);
    }
    await this.setCurrentState(state);
    return entity;
  }

  private async getOneFromServer(
    id: string,
    state: RepositoryData<T>
  ): Promise<T> {
    const entity = await this.client.get<T>(`${this.url}/${id}`).toPromise();
    state.dataBase[id] = entity;
    return entity;
  }

  private async appendPage(
    state: RepositoryData<T>,
    pageNumber: number,
    resetOrderOfItems?: boolean
  ): Promise<void> {
    const page = await this.getPageFromServer(pageNumber);
    await this.setStateFromPage(state, page, pageNumber, resetOrderOfItems);
  }

  private async setStateFromPage(
    newState: RepositoryData<T>,
    page: TotvsPage<T>,
    pageNumber: number,
    resetOrderOfItems?: boolean
  ): Promise<void> {
    newState.hasNext = page.hasNext;
    newState.lasPageFetched = pageNumber;
    if (resetOrderOfItems) {
      newState.orderOfItems = [];
    }
    for (const item of page.items) {
      newState.dataBase[item.id] = item;
      newState.orderOfItems.push(item.id);
    }
    await this.setCurrentState(newState);
  }
  get params(): {
    [header: string]: string | string[];
  } {
    const params: any = {
      ...this.options.propertyFilters,
    };
    if (this.options.order) {
      params.order = this.options.order;
    }
    if (this.options.queryFilter) {
      params.filter = this.options.queryFilter;
    }
    if (this.options.fields && this.options.fields.length > 0) {
      params.fields = this.options.fields.join(',');
    }
    return params;
  }

  private getOptions(
    pageNumber: number
  ): {
    [header: string]: string | string[];
  } {
    const params: any = {
      page: pageNumber,
      pageSize: this.options.pageSize,
      ...this.params,
    };
    return { params };
  }

  private async getPageFromServer(pageNumber: number): Promise<TotvsPage<T>> {
    return await this.client
      .get<TotvsPage<T>>(this.url, this.getOptions(pageNumber))
      .toPromise();
  }

  private GetOrder(columnSort: PoTableColumnSort) {
    let order = '';
    if (columnSort.type == PoTableColumnSortType.Descending) {
      order += '-';
    }
    order += columnSort.column?.property;
    return order;
  }
}
