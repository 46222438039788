import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  PoButtonModule,
  PoFieldModule,
  PoPopupModule,
  PoTooltipModule,
} from '@po-ui/ng-components';
import { EmptyTableComponent } from './components/empty-table/empty-table.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { PeriodComponent } from './components/period/period.component';
import { SliderElementDirective } from './components/slider/slider-element.directive';
import { SliderHandleDirective } from './components/slider/slider-handle.directive';
import { SliderLabelDirective } from './components/slider/slider-label.directive';
import { SliderComponent } from './components/slider/slider.component';
import { SpinLoaderComponent } from './components/spin-loader/spin-loader.component';
import { TitleServiceSupportComponent } from './components/title-service-support/title-service-support.component';
import { ExportExcelButtonComponent } from './components/export-excel-button/export-excel-button.component';
import { ExcelService } from '../core/excel/excel.service';
import { FileManipulationService } from '../core/file-manipulation/file-manipulation.service';

@NgModule({
  exports: [
    SpinLoaderComponent,
    SliderComponent,
    SliderElementDirective,
    SliderHandleDirective,
    SliderLabelDirective,
    GoBackComponent,
    EmptyTableComponent,
    TitleServiceSupportComponent,
    PeriodComponent,
    ExportExcelButtonComponent,
  ],
  declarations: [
    SpinLoaderComponent,
    SliderComponent,
    SliderElementDirective,
    SliderHandleDirective,
    SliderLabelDirective,
    GoBackComponent,
    EmptyTableComponent,
    TitleServiceSupportComponent,
    PeriodComponent,
    ExportExcelButtonComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PoButtonModule,
    PoPopupModule,
    PoFieldModule,
    FormsModule,
    PoTooltipModule,
  ],
  providers: [ExcelService, FileManipulationService],
})
export class SharedModule {}
