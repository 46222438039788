import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { RouteReuseStrategy } from '@angular/router/';

export const SAVE_ROUTE_PARAMETER = 'saveRoute';

export interface TplRouteReuseOnLoad {
  onRouteLoad(): void | Promise<void>;
}

function isRouteOnLoad(value: any): value is TplRouteReuseOnLoad {
  return (value as TplRouteReuseOnLoad).onRouteLoad !== undefined;
}

@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  private navigationStatus: {
    current: ActivatedRouteSnapshot;
    destination: ActivatedRouteSnapshot;
  };
  private storedRouteHandles = new Map<string, DetachedRouteHandle>();
  private shouldRetrieve = false;

  constructor(private zone: NgZone) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const param = this.navigationStatus.destination.queryParams[
      SAVE_ROUTE_PARAMETER
    ];
    const shouldDetach = param && param.toString() === 'true';
    return shouldDetach;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (handle) {
      this.storedRouteHandles.set(this.getPath(route), handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    const param = this.navigationStatus.current.queryParams[
      SAVE_ROUTE_PARAMETER
    ];
    this.shouldRetrieve =
      this.storedRouteHandles.has(path) && param && param.toString() === 'true';
    return this.shouldRetrieve;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path = this.getPath(route);
    if (this.storedRouteHandles.has(path) && this.shouldRetrieve) {
      const result: any = this.storedRouteHandles.get(path);
      this.storedRouteHandles.delete(path);
      if (result && result.componentRef && result.componentRef.instance) {
        const component = result.componentRef.instance;
        if (isRouteOnLoad(component)) {
          void this.zone.run(async () => {
            await component.onRouteLoad();
          });
        }
      }
      return result;
    }
    return null;
  }

  shouldReuseRoute(
    current: ActivatedRouteSnapshot,
    destination: ActivatedRouteSnapshot
  ): boolean {
    this.shouldRetrieve = false;
    this.navigationStatus = { current, destination };
    return current.routeConfig === destination.routeConfig;
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    if (route['_routerState'] && route['_routerState'].url) {
      return route['_routerState'].url;
    }
    return '';
  }
}
