import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderOptionsInterface } from '@totvs-apps-components/header';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ComponentBase } from './core/component.base';
import { MenuService } from './core/menu/menu.service';
import { MenuItem } from './shared/models/menu-item.model';
import { UserService } from './core/user/user.service';
import { ProfileService } from './core/profile/profile.service';

@Component({
  selector: 'ngx-payroll-loan-admin',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent
  extends ComponentBase
  implements OnInit, AfterViewInit
{
  headerConfig: HeaderOptionsInterface;
  private subscriptions: Subscription[] = [];

  constructor(
    public menuService: MenuService,
    public userService: UserService,
    public profilerService: ProfileService,
    private router: Router,
    translate: TranslateService
  ) {
    super();
    translate.setDefaultLang('pt');
    translate.use(translate.getBrowserLang());
  }

  ngAfterViewInit(): void {
    document.body.classList.remove('fluig-style-guide');
  }

  async ngOnInit(): Promise<void> {
    this.router.initialNavigation();
    this.subscribeToMenuService();
    await this.userService.initUser();
    await this.menuService.initAuthorizedMenus();
    //this.setHaderConfig();
  }

  // private setHaderConfig(): void {
  //   this.headerConfig = {
  //     appName: 'consignado',
  //     appThemeClass: COLOR_LOADER_DEFAULT,
  //     logoAppLink: '/',
  //     logoAppUrl: this.getFileURL('assets/img/logo-techfin.svg'),
  //     isFixed: true,
  //     enableContainer: false,
  //     enableAppSettings: true,
  //     enableNotifications: false,
  //   };
  // }

  private subscribeToMenuService(): void {
    this.subscriptions.push(
      this.menuService.state$.subscribe(async (authorizedMenus) => {
        await this.redirectRoute(authorizedMenus);
      })
    );
  }

  private async redirectRoute(authorizedMenus: MenuItem[]): Promise<void> {
    const currentRoute = sessionStorage.getItem('current.route');
    if (currentRoute) {
      const existsMenu = authorizedMenus.find(
        (menu) => currentRoute.indexOf(menu.link) > -1
      );
      if (existsMenu) {
        await this.router.navigateByUrl(currentRoute);
      } else {
        await this.router.navigate(['']);
      }
    } else {
      await this.router.navigate(['']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
