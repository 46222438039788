import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@totvs-apps-components/auth';
import { OAuthLoginComponent } from './oauth-login';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'support', pathMatch: 'full' },
  {
    path: 'support',
    loadChildren: () =>
      import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-access',
    loadChildren: () =>
      import('./user-access/user-access.module').then(
        (m) => m.UserAccessModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'contract-update',
    loadChildren: () =>
      import('./management/contract-update/contract-update.module').then(
        (m) => m.ContractUpdateModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'installment-audit',
    loadChildren: () =>
      import('./management/installment-audit/installment-audit.module').then(
        (m) => m.InstallmentAuditModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'future-installments',
    loadChildren: () =>
      import(
        './management/future-installments/future-installments.module'
      ).then((m) => m.FutureInstallmentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'control-panel',
    loadChildren: () =>
      import(
        './management/platform-management/platform-management.module'
      ).then((m) => m.PlatformManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'installment-update',
    loadChildren: () =>
      import('./management/installment-update/installment-update.module').then(
        (m) => m.InstallmentUpdateModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'environment-maitenance',
    loadChildren: () =>
      import(
        './management/environment-maitenance/environment-maitenance.module'
      ).then((m) => m.EnvironmentMaitenanceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event-reprocessing',
    loadChildren: () =>
      import(
        './management/event-reprocessing/event-reprocessing.module'
      ).then((m) => m.EventReprocessingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'oauth/login',
    component: OAuthLoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '' }],
})
export class AppRoutingModule {}
