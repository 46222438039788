import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../../shared/models/menu-item.model';
import { MENU_ITEMS } from '../../shared/settings/menu.settings';
import { State } from '../../shared/state/state';
import { FlaggedEnum } from 'src/app/shared/models/flag-enum-model';
import { AccessTypeEnumFlag } from 'src/app/shared/enums/access-type-flag.enum';
import { Profile } from 'src/app/shared/models/profile.model';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class MenuService extends State<MenuItem[]> {
  private menuItems = MENU_ITEMS;
  private authorizedMenus = [];
  profile: Profile;
  FlaggedExample = FlaggedEnum.create(AccessTypeEnumFlag, 1 << 3);
  profilePermission: any;

  constructor(
    private translate: TranslateService,
    private userService: UserService
  ) {
    super();
  }

  async initAuthorizedMenus(): Promise<void> {
    try {
      this.profile = await this.userService.initUser();
      this.authorizedMenus = await this.checkAuthorizedItems();
      await this.setCurrentState(this.authorizedMenus);
    } catch (error) {
      console.log('Erro na inicialização de Menus. Erro: ' + error);
    }
  }

  private async checkAuthorizedItems(): Promise<MenuItem[]> {
    this.profilePermission = new this.FlaggedExample(this.profile.access);
    this.authorizedMenus = [];
    this.menuItems.forEach((menu) => {
      const dynamicPermissionSum = menu.dynamicPermission.reduce(
        (sum, permission) => sum | permission,
        0
      );
      if (
        (this.profile.access & dynamicPermissionSum) ===
        dynamicPermissionSum
      ) {
        this.authorizedMenus.push(menu);
        if (menu.subItems) {
          this.translateLabels(menu.subItems);
        }
      }
    });
    return this.translateLabels(this.authorizedMenus);
  }

  private translateLabels(items: MenuItem[]): MenuItem[] {
    items = items.map((item) => {
      this.translate.get([item.label]).subscribe((translate) => {
        item.label = translate[item.label];
      });
      return item;
    });
    return items;
  }
}
