import { Component } from '@angular/core';
import { HeaderOptionsInterface } from '@totvs-apps-components/header';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  headerConfig: HeaderOptionsInterface = {
    appName: 'Portal Admin',
    logoAppLink: '/',
    helpUrl: '',
    isFixed: true,
    enableContainer: false,
    enableAppSettings: false,
    enableProductsAlertBadge: true,
    enableRealtime: false,
    enableNotifications: false,
    appSettings: {
      title: 'Portal Admin',
      list: [
        {
          title: 'About',
          route: '/about',
        },
      ],
    },
    menu: [],
  };
}
