import {
  APP_BASE_HREF,
  CommonModule,
  registerLocaleData,
} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localeExtraEN from '@angular/common/locales/extra/en';
import localeExtraES from '@angular/common/locales/extra/es';
import localeExtraPT from '@angular/common/locales/extra/pt';
import localePT from '@angular/common/locales/pt';
import { AuthModule, AuthModuleOptions } from '@totvs-apps-components/auth';
import { DoBootstrap, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PoChartModule, PoModule } from '@po-ui/ng-components';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { CoreModule } from './core/core.module';
import { CacheRouteReuseStrategy } from './core/route-reuse-strategy/route-reuse-strategy';
import { HeaderModule } from './header/header.module';
import { OauthLoginModule } from './oauth-login/oauth-login.module';
import { SharedModule } from './shared/shared.module';
import { AppHeaderModule } from '@totvs-apps-components/header';

registerLocaleData(localePT, 'pt', localeExtraPT);
registerLocaleData(localeES, 'es', localeExtraES);
registerLocaleData(localeEN, 'en', localeExtraEN);

const authOptions: AuthModuleOptions = {
  config: {
    shouldPromptTokenRequest: environment.shouldPromptTokenRequest,
    appCodeDevMode: environment.appCodeDevMode,
    DEV: environment.DEV,
    STAGING: environment.STAGING,
    PROD: environment.PROD,
    pkceEnabled: false,
  },
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.clientBaseAddress}/assets/i18n/`
  );
}

export function translateServiceLoader(translate: TranslateService): string {
  return translate.currentLang || translate.getBrowserLang();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PoModule,
    PoChartModule,
    HttpClientModule,
    SharedModule,
    OauthLoginModule,
    HeaderModule,
    AuthModule.forRoot(authOptions),
    AppHeaderModule.forRoot(authOptions),
    AppRoutingModule,
    RouterModule,
    RouterModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: translateServiceLoader,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
    { provide: APP_BASE_HREF, useValue: '' },
  ],
  entryComponents: [AppComponent],
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {
    const el = createCustomElement(AppComponent, {
      injector: this.injector,
    });
    customElements.define('ngx-payroll-loan-admin', el);
  }
  ngDoBootstrap() {}
}
