export class RepositoryData<T> {
  dataBase: {
    [key: string]: T;
  } = {};
  orderOfItems: string[] = [];
  lasPageFetched = 0;
  hasNext = true;
  get all(): T[] {
    return this.orderOfItems.map((id) => this.dataBase[id]);
  }
}
