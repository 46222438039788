import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { URL_API } from 'src/environments/app.api';
import { TotvsQueryOptions } from 'src/app/shared/models/totvs';
import { UserRequest } from 'src/app/shared/models/user-request.model';
import { UserResponse } from 'src/app/shared/models/user-response.model';
import { Repository } from 'src/app/shared/repository';
import { PersonLogged } from 'src/app/shared/models/person-logged.model';
import { Profile } from 'src/app/shared/models/profile.model';

const URL_USERS = `${URL_API}/user-access`;
export const PERSON_ME_API = `${URL_USERS}/me`;
@Injectable({
  providedIn: 'root',
})
export class UserService {
  mainRepository: Repository<UserResponse> | null = null;
  public state = new ReplaySubject(1);

  constructor(private http: HttpClient) {}

  async initializeService(): Promise<void> {
    this.mainRepository = this.createRepository(`${URL_USERS}`, {
      pageSize: 10,
    });
    await this.mainRepository.initToFirstPage();
  }

  private createRepository(
    url: string,
    options: TotvsQueryOptions
  ): Repository<UserResponse> {
    return new Repository(url, this.http, true, options);
  }
  checkExistsUserSameEmail(email: string): Observable<boolean> {
    const params = { email };
    return this.http.get<boolean>(`${URL_USERS}/check/same-email`, {
      params,
    });
  }
  async create(user: UserRequest): Promise<UserResponse> {
    return await this.http.post<UserResponse>(`${URL_USERS}`, user).toPromise();
  }
  async update(user: UserRequest): Promise<UserResponse> {
    return await this.http.put<UserResponse>(`${URL_USERS}`, user).toPromise();
  }
  async delete(id: string): Promise<UserResponse> {
    return await this.http
      .delete<UserResponse>(`${URL_USERS}/${id}`)
      .toPromise();
  }
  async setFilter(
    filter?: string,
    name?: string,
    email?: string,
    team?: number,
    accessType?: number
  ): Promise<void> {
    const propertyFilters = {};

    if (filter) {
      propertyFilters['filter'] = filter;
    }
    if (name) {
      propertyFilters['name'] = name;
    }
    if (email) {
      propertyFilters['email'] = email;
    }
    if (team) {
      propertyFilters['team'] = team;
    }
    if (accessType) {
      propertyFilters['access'] = accessType;
    }

    this.mainRepository.setPropertyFilters(propertyFilters);
  }

  async setCurrentState(newState: any): Promise<any> {
    this.state.next(newState);
    return newState;
  }

  async initUser(): Promise<Profile> {
    const personLogged = await lastValueFrom(
      this.http.get<PersonLogged>(`${PERSON_ME_API}`)
    );
    const profile: Profile = {
      access: personLogged?.access,
      name: personLogged?.name,
      admin: personLogged?.admin,
      email: personLogged?.email,
      surname: personLogged.surname,
      team: personLogged.team,
    };

    await this.setCurrentState(profile);

    return profile;
  }
}
