import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PoPageModule } from '@po-ui/ng-components';
import {
  AppHeaderModule,
  HeaderModuleOptions,
} from '@totvs-apps-components/header';

import { environment } from './../../environments/environment';
import { HeaderComponent } from './header.component';

const authOptions: HeaderModuleOptions = {
  config: {
    DEV: environment.DEV,
    STAGING: environment.STAGING,
    PROD: environment.PROD,
  },
};

@NgModule({
  imports: [CommonModule, PoPageModule, AppHeaderModule.forRoot(authOptions)],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
