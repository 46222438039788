import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class FileManipulationService {
  constructor() {}

  public formatFilenameReport(
    names: string[],
    extension: string = '.pdf'
  ): string {
    let filename: string;
    if (names && names.length > 0) {
      filename = names.join('_').replace(/\s/g, '_') + extension;
    } else {
      filename = this.generateRandomFilename() + extension;
    }
    return filename;
  }

  public saveAs(response: Blob, mediaType: string, fileName: string) {
    const blob = new Blob([response], { type: mediaType });
    saveAs(blob, fileName);
  }

  public generateRandomFilename(): string {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 12);
  }
}
