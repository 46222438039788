import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PoNotificationModule } from '@po-ui/ng-components';
import { Interceptor } from './interceptor/interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];

@NgModule({
  providers: [httpInterceptorProviders],
  exports: [FormsModule, CommonModule, TranslateModule],
  imports: [PoNotificationModule],
})
export class CoreModule {}
