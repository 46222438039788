import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AuthTokenService,
  UrlHelperService,
} from '@totvs-apps-components/auth';
import { WINDOW } from 'ngx-window-token';

@Component({
  selector: 'app-oauth-login',
  template: '',
})
export class OAuthLoginComponent {
  authorizationCode: string;
  appCode: string;
  forward: string;

  constructor(
    private route: ActivatedRoute,
    private authTokenService: AuthTokenService,
    private urlHelperService: UrlHelperService,
    @Inject(WINDOW) private _window: Window
  ) {
    this.route.queryParams.subscribe((params) => {
      this.forward = params.forward;
      this.authorizationCode = params.code;

      this.appCode = this.urlHelperService.getSubdomain(
        window.location.hostname
      );

      this.getToken(this.appCode, this.authorizationCode);
    });
  }

  getToken(appCode: string, authorizationCode: string): void {
    this.authTokenService.getToken(appCode, authorizationCode, this.forward);
  }
}
